import type { AppProps } from "next/app";
import { ChakraProvider, useToast, extendTheme } from "@chakra-ui/react";
import { createContext, useEffect, useState } from "react";

// Store Global object in context
type NotificationType = {
  title: string;
  description: string;
  status: "info" | "warning" | "success" | "error" | "loading" | undefined;
  duration: number;
  isClosable: boolean;
};

type DrawerType = {
  isOpen: boolean;
};

type BillType = {
  items: [ItemType];
  remainingItems: [ItemType];
  totalBill: number;
  currentShare: number;
  remaining: number;
  tips: number;
  currency: string;
  tipsOptions: number[];
};

export type ItemType = {
  total_qty: number;
  id: number;
  name: string;
  qty: number;
  price: number;
  currency: string;
  total?: number;
};

type GlobalDataType = {
  notification: NotificationType;
  bill: BillType;
  splitBillDrawer: DrawerType;
  customAmountDrawer: DrawerType;
  yourItemsDrawer: DrawerType;
  divideBillDrawer: DrawerType;
};

type GlobalContextType = [
  GlobalDataType | null,
  React.Dispatch<React.SetStateAction<GlobalDataType | null>>
];

export const GlobalContext = createContext<GlobalContextType>([
  null,
  () => null,
]);
const theme = extendTheme({
  colors: {
    brand: {
      50: "#ddf290",
      100: "#d8f07e",
      200: "#d2ee6b",
      300: "#d2ee6b",
      400: "#cdec59",
      500: "#C7EA46",
      600: "#b3d33f",
      700: "#9fbb38",
      800: "#8ba431",
      900: "#778c2a",
    },
  },
  components: {
    Button: {
      variants: {
        light: {
          bg: "brand.500",
          color: "black",
          _hover: {
            bg: "brand.50",
          },
        },
        dark: {
          bg: "black",
          color: "white",
          _hover: {
            bg: "blackAlpha.700",
          },
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          _checked: {
            bg: "brand.500", // Change the background color of the checked icon
            borderColor: "brand.500", // Change the border color of the checked icon
            color: "black", // Change the checkmark color (for contrast)
            _before: {
              borderColor: "brand.500",
            },
            _icon: {
              color: "black", // Change the color of the checkmark icon
            },
          },
        },
      },
    },
    Radio: {
      baseStyle: {
        control: {
          _checked: {
            bg: "brand.500",
            borderColor: "brand.500",
            color: "black", // Change the dot color
          },
          _hover: {
            _checked: {
              bg: "brand.50", // Change the background color when hovered over
              borderColor: "brand.50", // Change the border color when hovered over
              color: "black", // Change the dot color when hovered over
            },
          },
        },
      },
    },
  },
});

export default function App({ Component, pageProps }: AppProps) {
  const [globalData, setGlobalData] = useState<GlobalDataType | null>(null);

  const toast = useToast();

  useEffect(() => {
    if (globalData && globalData.notification) {
      const { title, description, status, duration, isClosable } =
        globalData.notification;

      toast({
        title,
        description,
        status,
        duration,
        isClosable,
      });
    }
  }, [globalData, toast]);

  return (
    <ChakraProvider theme={theme}>
      <GlobalContext.Provider value={[globalData, setGlobalData]}>
        <Component {...pageProps} />
      </GlobalContext.Provider>
    </ChakraProvider>
  );
}
